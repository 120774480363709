import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  FormControlLabel,
  Chip,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Delete, Edit, ExpandMore } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import logo from "assets/images/LogoAina.svg";

const CustomTable = ({ columns, data = [] }) => {
  return (
    <Card
      sx={{
        width: "100%",
        overflowX: "auto",
        margin: "20px auto",
        boxShadow: 3,
        marginBottom: "100px",
        marginTop: "20px",
        paddingTop: "15px",
      }}
    >
      <CardContent>
        <Box sx={{ minWidth: 650, borderRadius: 1 }}>
          <Grid
            container
            sx={{
              backgroundColor: "#f5f5f5",
              padding: "8px 0",
              borderRadius: "12px",
            }}
          >
            {columns.map((column, index) => (
              <Grid
                item
                xs={12 / columns.length}
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    borderRadius: "12px",
                  }}
                >
                  {column.headerName}
                </Typography>
              </Grid>
            ))}
          </Grid>

          {data.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px 0",
              }}
            >
              <InfoIcon sx={{ color: "#ff5722", marginRight: "8px" }} />
              <Typography variant="body2" align="center" sx={{ color: "red" }}>
                No data available
              </Typography>
            </Box>
          ) : (
            data.map((row, rowIndex) => (
              <Grid
                container
                key={rowIndex}
                sx={{
                  padding: "8px 0",
                  "&:hover": {
                    backgroundColor: "#f9f9f9",
                    borderRadius: "12px",
                  },
                  display: "flex",
                  alignItems: "center",
                }}
                mt={4}
              >
                {columns.map((column, colIndex) => (
                  <Grid
                    item
                    xs={12 / columns.length}
                    key={colIndex}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="body2"
                      align="center"
                      sx={{
                        color: "#555",
                        fontWeight: colIndex === 0 ? "bold" : "regular",
                      }}
                    >
                      {column.field === "remolque" ? (
                        row.remolque === "1" ? (
                          <Chip
                            label="Tiene Remolque"
                            sx={{
                              color: "#FFFFFF",
                              backgroundColor: "#f44336",
                            }}
                          />
                        ) : (
                          <Chip
                            label="No tiene Remolque"
                            sx={{
                              color: "#FFFFFF",
                              backgroundColor: "#9e9e9e",
                            }}
                          />
                        )
                      ) : column.field === "is_active" ? (
                        row.is_active === "1" ? (
                          <Chip
                            label="Activo"
                            sx={{
                              color: "#FFFFFF",
                              backgroundColor: "#4caf50",
                            }}
                          />
                        ) : (
                          <Chip
                            label="No Activo"
                            sx={{
                              color: "#FFFFFF",
                              backgroundColor: "#9e9e9e",
                            }}
                          />
                        )
                      ) : row[column.field] === "" ? (
                        <Chip
                          label="Sin info"
                          sx={{ color: "#FFFFFF", backgroundColor: "#9e9e9e" }}
                        />
                      ) : (
                        row[column.field]
                      )}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            ))
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomTable;
