import React from "react";
import { Grid, Box, Link, Typography } from "@mui/material";
import { FaInstagram, FaLinkedin, FaTiktok, FaFacebook } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import logoaina from "assets/images/isoAina.png";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      component="footer"
      sx={{ backgroundColor: "#031b27", color: "#FFFFFF", py: 4 }}
    >
      <Grid
        container
        item
        xs={10}
        lg={8}
        justifyContent="center"
        sx={{ mx: "auto", textAlign: "center" }}
      >
        <Grid item xs={12} sm={4} mb={4}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mb={2}
          >
            <Box
              component="img"
              src={logoaina}
              alt="Logo"
              sx={{ height: 70, width: 70, mr: 2 }}
            />
            <Typography sx={{ color: "#FFFFFF" }} variant="h6">
              Ainacar Grup
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="body2" sx={{ textAlign: "left" }}>
              C/ de Joaquín Costa, 50.
              <br />
              08206 Sabadell (BCN)
              <br />
              Tlf: 937 273 907
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} mb={4}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box sx={{ textAlign: "left" }}>
              <Typography
                mb={2}
                variant="h6"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                {t("schedule")}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                {t("montay_to_friday")}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#FFFFFF", fontWeight: "regular" }}
              >
                08:00h a 13:00h
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#FFFFFF", fontWeight: "regular" }}
              >
                16:00h a 19:00h ({t("august")} 15:00h a 18:00h)
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                {t("Saturday")}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: "#FFFFFF", fontWeight: "regular" }}
              >
                09:00h a 13:00h
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} mb={4}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box sx={{ textAlign: "left" }}>
              <Typography
                mb={2}
                variant="h6"
                sx={{ color: "#FFFFFF", fontWeight: "bold" }}
              >
                {t("aina_group")}
              </Typography>
              {[
                { text: "Aina Car Protect", href: "/protect" },
                { text: t("FAQ"), href: "/faqs" },
                { text: t("Legal_Notice"), href: "/aviso-legal" },
                {
                  text: t("Damage_Management_Policy"),
                  href: "/politica-gestion-de-danos",
                },
                {
                  text: t("General_Terms_and_Conditions"),
                  href: "/terminos-y-condiciones-generales",
                },
                { text: t("Privacy_Policy"), href: "/politica-privacidad" },
                { text: t("Cookies_policy"), href: "/politica-cookies" },
                {
                  text: t("Information_before_rental"),
                  href: "/informacion-antes-de-alquilar",
                },
                {
                  text: t("Guide_to_additional_charges"),
                  href: "/guia-de-cargos-adicionales",
                },
              ].map((item, index) => (
                <Link
                  key={index}
                  onClick={() => navigate(item.href)}
                  color="inherit"
                  sx={{ display: "block", color: "#FFFFFF", mb: 0 , cursor: 'pointer'}}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#FFFFFF", fontWeight: "regular" }}
                  >
                    {item.text}
                  </Typography>
                </Link>
              ))}
            </Box>
          </Box>
        </Grid>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          sx={{ px: 2, mt: 4 }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#FFFFFF",
              textAlign: { xs: "center", sm: "left" },
              mb: { xs: 2, sm: 0 },
            }}
          >
            © Copyright 2024 Ainacar Grup - {t("rights_reserved")}.
          </Typography>
          <Box>
            {[
              {
                icon: <FaFacebook />,
                href: "https://www.facebook.com/AinaCar.LloguerVehicles/",
              },
              {
                icon: <FaInstagram />,
                href: "https://www.instagram.com/ainacar_rentacar/",
              },
              {
                icon: <FaLinkedin />,
                href: "https://www.linkedin.com/company/aina-car-sl-alquiler-de-veh-culos/",
              },
              {
                icon: <FaTiktok />,
                href: "https://www.tiktok.com/@ainacar.rentacar",
              },
            ].map((item, index) => (
              <Link
                key={index}
                href={item.href}
                color="inherit"
                sx={{ color: "#FFFFFF", ml: 1 }}
              >
                {item.icon}
              </Link>
            ))}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default Footer;
