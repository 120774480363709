import * as React from "react";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
//import PopUpImage from 'assets/images/popupimage.jpg';
//import PopUpImagedesk from 'assets/images/MAYORES 60 AÑOS.png';

import { Box, Button } from "@mui/material";

export default function MaxWidthDialog() {
  const [open, setOpen] = useState(false);
  const [popUp, setPopUp] = useState([]);
  const isMobileDevice = window.innerWidth <= 900;

  const token = "mi-token-estatico";

  useEffect(() => {
    const fetchPopUp = async () => {
      try {
        const fetchedPopUp = await axios.get(`/api/public/popup?token=${token}`);
        setPopUp(fetchedPopUp.data[0]);
      } catch (error) {
        console.error("Error al obtener el Pop Up:", error);
      }
    };

    fetchPopUp();
  }, [token]);


/*
  const infoPopUp = {
    is_active: "1",
    image_url: PopUpImage,
    url_imagen_movil: PopUpImagedesk,
    start_date: '2024-09-16 00:00:00',
    end_date: '2024-09-30 00:00:00'
   }
*/
  const today_date = new Date();
  const start_date = new Date(popUp.start_date);
  const end_date = new Date(popUp.end_date);

  const handleClose = () => {
    setOpen(false);
    // Guardar en sessionStorage cuando se cierra el popup
    sessionStorage.setItem("popupShown", "true");
  };

  useEffect(() => {
    const popupShown = sessionStorage.getItem("popupShown");

    if (
      popUp.is_active === "1" &&
      today_date > start_date &&
      today_date < end_date &&
      !popupShown // Verifica que no se haya mostrado antes
    ) {
      const timer = setTimeout(() => {
        setOpen(true);
      }, 2000); // Mostrar después de 2 segundos

      return () => clearTimeout(timer);
    }
  }, [popUp.is_active, today_date, start_date, end_date]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            height: "auto",
            width: "90%", // Ajuste del ancho del popup
            maxWidth: isMobileDevice? "400px" : "700px", // Control de tamaño máximo
            margin: "0 auto", // Centrado en la pantalla
          },
        }}
      >
        <Box
          p={0}
          mx={0}
          my={0}
          sx={{
            borderRadius: "0",
            overflow: "hidden",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "@media (max-width: 600px)": {
              height: "auto", // Ajuste para móviles
            },
          }}
        >
          <Box sx={{ position: "absolute", top: "5px", right: "5px" }}>
            <Button
              sx={{ color: "#FFFFFF", backgroundColor: "rgba(0, 0, 0, 0.2)" }}
              onClick={handleClose}
            >
              Cerrar
            </Button>
          </Box>
          <Box
            p={0}
            mx={0}
            my={0}
            component="img"
            src={isMobileDevice? popUp.url_imagen_movil : popUp.image_url }
            alt="Example Image"
            sx={{
              borderRadius: "0",
              objectFit: {
                xs: "contain", // Para vistas pequeñas
                sm: "cover", // Para vistas medianas en adelante
              },
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "@media (max-width: 600px)": {
                height: "auto",
              },
            }}
          />
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
