import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Box, Accordion, AccordionSummary, AccordionDetails, Typography, Button, Avatar, AvatarGroup, Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import icon_engine from 'assets/images/icon-engine.png';
import icon_gear from 'assets/images/icon-gear.png';
import icon_people from 'assets/images/icon-people.png';
import icon_bag from 'assets/images/icon-maletas.png';
import etiqueta0 from 'assets/images/et0.png';
import etiquetac from 'assets/images/etc.png';
import etiquetaeco from 'assets/images/eteco.png';
import etiquetab from 'assets/images/etb.png';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

function CenteredBlogCardProduct2({
  image,
  title,
  description,
  action,
  precio,
  medio,
  motor,
  cambio,
  cuota,
  espec,
  entrega,
  modelo2,
  remolque,
  capacidad,
  categoria,
  reservationDrawer,
  personas,
  maletas,
  actualizarEstadoPadre,
  link,
  remolque_sticker,
  capacidad_bol,
  remolque_bol
}) {
  const [estadoHijo, setEstadoHijo] = useState(link);

  const manejarCambio = () => {
    setEstadoHijo(link);
    actualizarEstadoPadre(link);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };



  const isRemolqueEmpty = String(remolque_bol) === "0" || !remolque || !remolque.carnet;
  const isCapacidadEmpty = String(capacidad_bol) === "0" ||!capacidad || !capacidad.largo;

  const renderAccordion = () => (
    <MKBox mt={2}>
      <Accordion sx={{ boxShadow: 'none' }} disabled={isRemolqueEmpty}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography variant="caption" sx={{ fontSize: 'medium', color: '#a8a8a8', fontWeight: 'bold' }}>
            Remolque
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px', margin: '0px' }}>
          <MKBox p={1} sx={{ backgroundColor: '#dbeddc', borderRadius: '8px' }}>
            {isRemolqueEmpty ? (
              <Typography variant="body2" sx={{ fontSize: 'small', color: '#a8a8a8' }}>
                No hay información disponible.
              </Typography>
            ) : (
              <Box>
                {Object.entries(remolque)
                  .filter(([key]) => key !== 'id' && key !== 'item_id') // Filtra las claves id y item_id
                  .map(([key, value]) => (
                    <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <Typography variant="body2" sx={{ fontSize: 'small', color: '#242424' }}>
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}:
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: 'small', color: '#242424' }}>
                        {value}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            )}
          </MKBox>
        </AccordionDetails>
      </Accordion>
    </MKBox>
  );

  const renderAccordion2 = () => (
    <MKBox mt={2}>
      <Accordion sx={{ boxShadow: 'none' }} disabled={isCapacidadEmpty}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Typography variant="caption" sx={{ fontSize: 'medium', color: '#a8a8a8', fontWeight: 'bold' }}>
            Capacidad de Carga
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px', margin: '0px' }}>
          <MKBox p={1} sx={{ backgroundColor: '#ede9db', borderRadius: '8px' }}>
            {isCapacidadEmpty ? (
              <Typography variant="body2" sx={{ fontSize: 'small', color: '#a8a8a8' }}>
                No hay información disponible.
              </Typography>
            ) : (
              <Box>
                {Object.entries(capacidad)
                  .filter(([key]) => key !== 'id' && key !== 'item_id') // Filtra las claves id y item_id
                  .map(([key, value]) => (
                    <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <Typography variant="body2" sx={{ fontSize: 'small', color: '#242424' }}>
                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}:
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: 'small', color: '#242424' }}>
                        {value}
                      </Typography>
                    </Box>
                  ))}
              </Box>
            )}
          </MKBox>
        </AccordionDetails>
      </Accordion>
    </MKBox>
  );

  return (
    <Card style={{ backgroundColor: '#FFFFFF', boxShadow: 'none', marginTop: '25px' }}>
      <MKBox>
        <MKBox position="relative" mx={0} mt={0} mb={0} overflow="hidden">
          <div style={{ overflow: 'hidden', borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
            <img
              src={image}
              alt={title}
              style={{ width: '100%', borderRadius: '0', objectFit: 'cover' }}
            />
          </div>
          <MKBox position="absolute" sx={{ bottom: 0, left: 0, mb: '8px', ml: '8px', display: 'flex', alignItems: 'flex-end' }}>
            <AvatarGroup max={3}>
              {medio === "C" && (
                <Avatar sx={{ width: 35, height: 35 }} src={etiquetac} alt={`Avatar`} />
              )}
              {medio === "ECO" && (
                <Avatar sx={{ width: 35, height: 35 }} src={etiquetaeco} alt={`Avatar`} />
              )}
              {medio === "B-C" && (
                <>
                  <Avatar sx={{ width: 35, height: 35 }} src={etiquetab} alt={`Avatar`} />
                  <Avatar sx={{ width: 35, height: 35 }} src={etiquetac} alt={`Avatar`} />
                </>
              )}
            </AvatarGroup>
            {remolque_sticker === "1" && (
              <Chip 
                label="+ Bola Remolque Opcional" 
                color="primary" 
                sx={{ 
                  ml: 1, 
                  fontSize: '0.70rem', 
                  height: '25px', 
                  '& .MuiChip-label': {
                    padding: '0 8px', 
                  }
                }} 
              />
            )}
          </MKBox>
        </MKBox>

        <MKBox px={2} mt={0} textAlign="center" alignItems="center">
          <MKBox mt={0} mb={3} mx={1} display="flex" alignItems="center" justifyContent="space-between">
            <MKBox flex={1} sx={{ textAlign: 'left', minHeight: "70px", maxHeight: "70px", position: 'relative' }}>
              <MKBox mt={0} mb={-1}>
                <MKTypography display="inline" variant="h6" sx={{ color: '#242424', textTransform: 'capitalize', fontWeight: 'bold' }}>
                  {modelo2}
                </MKTypography>
              </MKBox>
            </MKBox>
          </MKBox>

          <MKBox p={1} display="grid" gridTemplateColumns="repeat(2, 1fr)" gridGap="10px" mb={1} sx={{ borderRadius: '6px', position: 'relative', minHeight: '110px' }}>
            <MKBox display="flex" alignItems="center">
              <img src={icon_people} style={{ width: '22px', marginLeft: '10px' }} alt="icon_people" />
              <MKTypography sx={{ ml: 1, color: '#a8a8a8' }} variant="body2">
                {personas}
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" marginRight="10px">
              <img src={icon_bag} style={{ width: '22px', marginLeft: '10px' }} alt="icon_bag" />
              <MKTypography sx={{ ml: 1, color: '#a8a8a8' }} variant="body2">
                {maletas}
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={2}>
              <img src={icon_engine} style={{ width: '24px', marginLeft: '10px' }} alt="icon_engine" />
              <MKTypography sx={{ ml: 1, color: '#a8a8a8' }} variant="body2">
                {motor}
              </MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={2}>
              <img src={icon_gear} style={{ width: '24px', marginLeft: '10px' }} alt="icon_gear" />
              <MKTypography sx={{ ml: 1, color: '#a8a8a8' }} variant="body2">
                {cambio}
              </MKTypography>
            </MKBox>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#a8a8a8',
                opacity: '10%',
                borderRadius: '6px',
                minHeight: '110px',
              }}
            />
          </MKBox>

          <MKBox pl={2} mt={2} mb={2} height="60px">
            <MKTypography variant="caption" component="p" sx={{ fontSize: 'medium', color: '#a8a8a8', fontWeight: 'bold', textAlign: 'left' }}>
              Equipamiento
            </MKTypography>
            <MKTypography variant="caption" component="p" sx={{ fontSize: 'small', color: '#a8a8a8', textAlign: 'left' }}>
            {Array.isArray(espec) ? espec.join(' | ') : espec}
            </MKTypography>
          </MKBox>

          <MKBox flex={1} sx={{ minHeight: '100px', position: 'relative' }}>
            <MKBox flex={1} sx={{ position: 'relative', marginBottom: '4px', opacity: isCapacidadEmpty ? '0.4 ' : '1' }}>
              {renderAccordion2()}
            </MKBox>
            <MKBox flex={1} sx={{ position: 'relative', marginBottom: '4px', opacity: isRemolqueEmpty ? '0.4 ' : '1' }}>
              {renderAccordion()}
            </MKBox>
          </MKBox>

          <Box sx={{ mt: 2 }}>
            <Button
              variant="outlined"
              sx={{ width: '100%', borderColor: '#d6061e', color: '#d6061e' }}
              onClick={() => {
                reservationDrawer();
                manejarCambio();
                scrollToTop();
              }}
            >
              Reservar categoría
            </Button>
          </Box>
        </MKBox>
      </MKBox>
    </Card>
  );
}

CenteredBlogCardProduct2.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light']),
    label: PropTypes.string.isRequired,
  }).isRequired,
  precio: PropTypes.string,
  medio: PropTypes.string.isRequired,
  motor: PropTypes.string.isRequired,
  cambio: PropTypes.string.isRequired,
  cuota: PropTypes.string,
  espec: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  entrega: PropTypes.string,
  modelo2: PropTypes.string.isRequired,
  remolque: PropTypes.object,
  capacidad: PropTypes.object,
  categoria: PropTypes.string.isRequired,
  reservationDrawer: PropTypes.func.isRequired,
  personas: PropTypes.string.isRequired,
  maletas: PropTypes.string.isRequired,
  actualizarEstadoPadre: PropTypes.func.isRequired,
  link: PropTypes.string.isRequired,
};

export default CenteredBlogCardProduct2;
